import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default function Setup() {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const Token = route.params.token;

  const submit = async (data = {}) => {
    data.Token = Token;
    await store
    .dispatch("changepassword", data)
    .then(() => {
      router.push({
        name: "Accueil",
      });
    })
  };

  return {
    submit
  }
}