<template>
  <div class="custom-size m-auto px-3">
    <div class="h-fit w-100">
      <CardContainer
        class="gradient m-auto mt-4 w-100 h-fit"
      >
        <form
          class="p-0 w-100"
          onsubmit="return false"
        >
          <!-- Old Password -->
          <div class="inside-block">
            <div class="bg-box">
              <div class="p-3">
                <MDBInput
                  id="OldPassword"
                  v-model="OldPassword"
                  type="password"
                  autocomplete="current-password"
                  required
                  :label="$tcust('common.form.oldPassword')"
                  class="text-center"
                />
              </div>
            </div>
          </div>

          <!-- New Password -->
          <div
            class="inside-block"
            :class="PasswordConfirmChecked.check === false ? 'bg-warning' : ''"
          >
            <div class="bg-box">
              <PasswordConfirm
                class="p-3"
                @checked="PasswordConfirmChecked = $event"
              />
            </div>
          </div>

          <!-- Submit button -->
          <div class="inside-block">
            <div class="bg-box">
              <div class="d-flex justify-content-center p-4">
                <MDBBtn
                  color="primary"
                  block
                  class="w-75 fw-bold"
                  :disabled="!PasswordConfirmChecked.check"
                  @click="checkSubmit"
                >
                  <span>{{ $tcust("common.form.validate") }}</span>
                </MDBBtn>
              </div>
            </div>
          </div>
        </form>
      </CardContainer>
    </div>
  </div>
</template>

<script setup>
import { MDBBtn, MDBInput } from "mdb-vue-ui-kit";
import PasswordConfirm from "@/components/form/PasswordConfirm";

import { ref } from "vue";
const PasswordConfirmChecked = ref({});
const OldPassword = ref(null);

const checkSubmit = () => {
  if (PasswordConfirmChecked.value.check) submit({ ...PasswordConfirmChecked.value.data, "OldPassword": OldPassword.value })
};

import Setup from "./ChangePasswordSetup";
const { submit } = Setup();
</script>

<style lang="scss" scoped>
.custom-size {
  max-width: 400px;
}
</style>
